import type {
  TaxRates,
  OrderRequestParams,
  OrderResponse,
  Inventory,
  Location,
} from '@hempitecture/types';
import type { LeadRequest, LeadInfo } from '@hempitecture/airtable';
import { useError } from '@/context';

export function useAirtable() {
  const { setError } = useError();

  /**
   * Get product information / inventory from Airtable and
   * save to Shipping Context
   */
  async function getInventory() {
    try {
      const inventory: Inventory = await fetch(`/api/get-inventory`).then(
        (res) => {
          if (res.status === 500) {
            setError('INVENTORY');
          }
          return res.json();
        }
      );
      return inventory;
    } catch (err) {
      setError('INVENTORY');
    }
  }

  /**
   * Get tax rates from Airtable and save to Shipping Context
   */
  async function getTaxRates() {
    try {
      const taxRates: TaxRates = await fetch(`/api/get-tax`).then((res) => {
        if (res.status === 500) {
          setError('TAX');
        }
        return res.json();
      });
      return taxRates;
    } catch (err) {
      setError('TAX');
    }
  }

  /**
   * Get locations information from Airtable
   */
  async function getLocations() {
    try {
      const distributors: Location[] = await fetch('/api/get-locations').then(
        (res) => {
          if (res.status === 500) {
            setError('DISTRIBUTOR');
          }
          return res.json();
        }
      );
      return distributors as Location[];
    } catch (err) {
      setError('DISTRIBUTOR');
    }
  }

  /**
   * Get location ranking from Google Matrix
   */
  async function getNearestDistributor(
    destination: string,
    locations: Location[]
  ) {
    try {
      const distributors: Location[] = await fetch(
        '/api/get-nearest-distributor',
        {
          method: 'POST',
          body: JSON.stringify({ destination, locations }),
        }
      ).then((res) => {
        if (res.status === 500) {
          setError('DISTRIBUTOR');
        }
        return res.json();
      });
      return distributors as Location[];
    } catch (err) {
      setError('DISTRIBUTOR');
    }
  }

  /**
   * Update Airtable Lead
   */
  async function updateAirtableLead(leadDetails: LeadRequest) {
    try {
      const data: LeadInfo = await fetch('/api/update-lead', {
        method: 'POST',
        body: JSON.stringify(leadDetails),
      }).then((res) => res.json());
      return data?.leadId;
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Create Airtable Order
   */
  async function createAirtableOrder(orderParams: Partial<OrderRequestParams>) {
    try {
      const { orderId }: OrderResponse = await fetch(`/api/create-order`, {
        method: 'POST',
        body: JSON.stringify(orderParams),
      }).then((res) => res.json());

      return orderId;
    } catch (err) {
      console.error(err);
    }
  }

  return {
    getInventory,
    getTaxRates,
    getLocations,
    getNearestDistributor,
    updateAirtableLead,
    createAirtableOrder,
  };
}
