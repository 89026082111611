import { useState, useContext, createContext } from 'react';
import { ErrorPopup } from '@/components/alerts/ErrorPopup';

/**
 * All error messages
 */
type ErrorMessage =
  | 'QUOTES'
  | 'INVENTORY'
  | 'TIMEOUT'
  | 'INVENTORY_LEVELS'
  | 'COYOTE'
  | 'TAX'
  | 'DISTRIBUTOR'
  | 'LOAD'
  | 'RECORD_ORDER'
  | 'SAVE_RATE'
  | 'PAYMENT'
  | 'CREDIT_CARD'
  | 'ACH';

export const errors = {
  QUOTES: 'There was an issue fetching carrier quotes for your order.',
  SAVE_RATE:
    'There was an issue saving your rate with your selected shipping provider.',
  INVENTORY:
    'There was an issue fetching our HempWool® inventory. Please check back and try again later.',
  TIMEOUT: 'Sorry, your session has expired. Please create a new order.',
  INVENTORY_LEVELS:
    "We're sorry, we don't currently have enough inventory to support your request. We will have a client success manager reach out to you to customize and plan your order directly. \n \n Sorry for the inconvenience!",
  COYOTE: 'There was an issue connecting to our shipping provider.',
  TAX: 'There was an issue fetching the latest state and local tax rates.',
  DISTRIBUTOR:
    'There was an issue identifying your nearest HempWool® distribution center.',
  LOAD: 'There was an issue booking this load with our 3rd-party carrier service.',
  PAYMENT: 'We experienced an issue connecting to our payment provider.',
  CREDIT_CARD: 'We experienced an issue processing your credit card payment.',
  ACH: 'We experienced an issue processing your ACH bank payment.',
  RECORD_ORDER:
    'Your order was successful, but there was an issue saving it. Please contact us to receive an order number and receipt directly.',
};

interface ErrorState {
  active?: boolean;
  message?: string;
}

interface ErrorContextType {
  state: ErrorState;
  setState: React.Dispatch<React.SetStateAction<ErrorState>>;
}

const ErrorContext = createContext<ErrorContextType>({
  state: {},
  setState: (s) => ({}),
});

/**
 * The `ErrorProvider` component wraps the application and displays an
 * error modal if the app experiences a critical server error.
 */
const ErrorProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState<ErrorState>({
    active: false,
    message: '',
  });

  return (
    <ErrorContext.Provider value={{ state, setState }}>
      {children}
      <ErrorPopup show={state.active} message={state.message} />
    </ErrorContext.Provider>
  );
};

function useError() {
  const { setState } = useContext(ErrorContext);

  function setError(messageKey: ErrorMessage) {
    setState({ active: true, message: errors[messageKey] });
  }

  return { setError };
}

export { ErrorProvider, useError };
