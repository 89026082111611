import { useState } from 'react';
import { useFormikContext } from 'formik';
import { generateId } from 'maker-ui';
import { FormValues } from '@hempitecture/types';
import { getGrandTotal } from '@hempitecture/utils';
import { useOrder, useShipment } from '@/context';

export type EventType =
  | 'Requested Shipping'
  | 'Selected Shipping'
  | 'Selected Pickup'
  | 'Cart Abandoned'
  | 'Order Placed'
  | 'Updated Cart';

export interface EventData {
  type: EventType;
  email?: string;
  skus?: number[];
  city?: string;
  state?: string;
  value?: number;
  shippingValue?: number | string;
  cartValue?: number | string;
  pickupLocation?: string;
  orderNumber?: number;
  uniqueId?: string;
}

export const useEvent = () => {
  const { shipping } = useShipment();
  const { values }: { values: FormValues } = useFormikContext();
  const { order } = useOrder();
  const [uniqueId] = useState(generateId(15));

  const createEvent = async (type: EventType) => {
    if (!values?.email) {
      return;
    }

    const total = getGrandTotal({
      inventory: order?.inventory,
      products: values?.products,
      shipping: shipping?.shipment?.totalRate,
    });

    try {
      await fetch('/api/create-event', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type,
          email: values.email,
          skus: values?.products?.map((p) => p?.sku) || [],
          city: values?.city,
          state: values?.state,
          shippingValue: total?.shippingTotal,
          cartValue: total?.total_products
            ? parseFloat(total?.total_products)
            : undefined,
          value: total?.total,
          pickupLocation: order?.selectedPickupLocation?.cityName,
          uniqueId: type === 'Order Placed' ? uniqueId : undefined,
          orderNumber: type === 'Order Placed' ? order?.orderNumber : undefined,
        } as EventData),
      });
    } catch (error) {
      console.error('Error creating event:', error);
    }
  };

  return { createEvent };
};
