import { MultiShipment, Shipment } from '@hempitecture/types';
import { useCoyote, usePrimus, useUberFreight } from '@/hooks';
import { useShipment } from '@/context';

interface Quotes {
  quotes: Shipment[];
  multiQuote: MultiShipment;
}

export function useQuoteFetcher() {
  const { shipping, setShipping } = useShipment();
  const { getCoyoteQuotes } = useCoyote();
  const { getPrimusRates } = usePrimus();
  const { getUberRates } = useUberFreight();

  function updateQuotes(
    res: Quotes,
    src: 'coyote' | 'primus' | 'uber',
    shipmentType: 'LTL' | 'Multi-LTL' = 'LTL'
  ) {
    if (!res?.quotes && !res?.multiQuote) return;
    const isMulti = shipmentType === 'Multi-LTL';
    setShipping((s) => ({
      ...s,
      multiQuotes: isMulti ? [...s.multiQuotes, res.multiQuote] : s.multiQuotes,
      quotes: !isMulti ? [...s.quotes, ...res.quotes] : s.quotes,
      fetchState: {
        ...s.fetchState,
        count: shipping.fetchState.count + 1,
        isLoading: false,
        coyote: src === 'coyote' ? false : s.fetchState.coyote,
        primus: src === 'primus' ? false : s.fetchState.primus,
        uber: src === 'uber' ? false : s.fetchState.uber,
      },
    }));
  }

  async function uberQuotes({ params, shipmentType, tokens }) {
    const res = await getUberRates(tokens.uber, {
      shipmentType,
      ...params,
    });
    if (res?.empty || res === undefined) {
      return setShipping((s) => ({
        ...s,
        fetchState: {
          ...s.fetchState,
          emptyQuotes: s.emptyQuotes + 1,
          uber: false,
        },
      }));
    }
    updateQuotes(res as Quotes, 'uber', shipmentType);
  }

  async function coyoteQuotes({ params, shipmentType, tokens }) {
    const res = await getCoyoteQuotes(tokens.coyote, {
      shipmentType,
      ...params,
    });
    if (res?.empty || res === undefined) {
      return setShipping((s) => ({
        ...s,
        fetchState: {
          ...s.fetchState,
          emptyQuotes: s.emptyQuotes + 1,
          coyote: false,
        },
      }));
    }
    updateQuotes(res as Quotes, 'coyote', shipmentType);
  }

  async function primusQuotes({ params, shipmentType, tokens }) {
    const res = await getPrimusRates(tokens.primus, {
      shipmentType,
      ...params,
    });
    if (res?.empty || res === undefined) {
      return setShipping((s) => ({
        ...s,
        fetchState: {
          ...s.fetchState,
          emptyQuotes: s.emptyQuotes + 1,
          primus: false,
        },
      }));
    }
    updateQuotes(res as Quotes, 'primus', shipmentType);
  }

  // Run Queries Independently
  function fetchQuotes(quoteParams) {
    coyoteQuotes(quoteParams);
    primusQuotes(quoteParams);
    uberQuotes(quoteParams);
  }

  function resetQuote(full = false) {
    setShipping((s) => ({
      ...s,
      multiShipmentSelected: false,
      quotes: [],
      multiQuotes: [],
      multiShipment: undefined,
      shippingRate: undefined,
      fetchState: {
        ...s.fetchState,
        isLoading: false,
        emptyQuotes: 0,
        requestSubmitted: full ? false : s.fetchState.requestSubmitted,
        showCarousel: true,
      },
      shipment: undefined,
    }));
  }

  const isLoadingMore =
    shipping.fetchState.coyote ||
    shipping.fetchState.primus ||
    shipping.fetchState.uber;

  return {
    fetchQuotes,
    resetQuote,
    quotes: shipping.quotes.sort((a, b) => a.totalRate - b.totalRate),
    multiQuotes: shipping.multiQuotes,
    isLoading: shipping.fetchState.isLoading,
    requestSubmitted: shipping.fetchState.requestSubmitted,
    isLoadingMore,
  };
}
