import { useEffect, useState, createContext, useContext } from 'react';
import { useFormikContext } from 'formik';
import { getGrandTotal, formatNumber } from '@hempitecture/utils';
import type {
  FormValues,
  Product,
  CartItem,
  Location,
  OrderRequestParams,
  ShipmentType,
  OriginAPI,
  Partner,
  PaymentType,
  FulfillmentType,
} from '@hempitecture/types';
import { useAirtable } from '@/hooks';

/* Local Type Declarations */
interface UpdateLeadProps {
  updateProducts?: boolean;
  shippingTotal?: number;
  conversion?: boolean;
  shipDetails?: boolean;
}

interface OrderProps {
  stripeTotal: number;
  stripeId: string;
  shipping_total?: number;
  bolNumber?: string[];
  bolUrl?: string[];
  loadRequestId?: string | string[];
  pickupTaxRate?: number;
  orderNumber: number;
  carrier?: string;
  orderType: PaymentType;
  shipmentType?: ShipmentType;
  shippingWarehouse?: string;
  shippingProvider?: OriginAPI;
}

export type ProductType = 'hempwool' | 'hempcrete';

export interface OrderState {
  leadId: string;
  orderId: string;
  isProcessing: boolean;
  partner: Partner;
  paymentType: PaymentType;
  fulfillmentType: FulfillmentType;
  pickupLocations: Location[];
  selectedPickupLocation: Location;
  orderComplete: boolean;
  orderNumber: number;
  total_products: string; // Formatted
  stripeId: string;
  stripeAmount: number;
  palletCount: number;
  productType: ProductType;
  hempcreteType: 'block' | 'cast';
  taxRates: {
    [key: string]: number;
  };
  inventory: {
    [key: string]: Partial<Product>;
  };
}

const OrderContext = createContext<{
  order: Partial<OrderState>;
  setOrder: React.Dispatch<React.SetStateAction<Partial<OrderState>>>;
}>({ order: {}, setOrder: () => {} });

/**
 * The `OrderProvider` stores  data about the state of the current order.
 * It initializes by querying Airtable for the latest inventory and tax rates.
 */
const OrderProvider = ({ children }: { children: React.ReactNode }) => {
  // const { setError } = useError();
  const { getInventory, getTaxRates, getLocations } = useAirtable();
  const [order, setOrder] = useState<Partial<OrderState>>({
    paymentType: 'Credit Card',
    inventory: {},
    orderComplete: false,
    isProcessing: false,
  });

  async function initAppMetrics() {
    const inventory = await getInventory();
    const taxRates = await getTaxRates();
    const pickupLocations = await getLocations();
    setOrder((s) => ({ ...s, inventory, taxRates, pickupLocations }));
  }

  useEffect(() => {
    initAppMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OrderContext.Provider value={{ order, setOrder }}>
      {children}
    </OrderContext.Provider>
  );
};

function useOrder() {
  const { getLocations, updateAirtableLead, createAirtableOrder } =
    useAirtable();
  const { values }: { values: FormValues } = useFormikContext();
  const { order, setOrder } = useContext(OrderContext);

  /**
   * Update lead entry in Airtable throughout the form fill process
   */
  async function updateLead({
    shippingTotal,
    conversion,
    shipDetails,
  }: UpdateLeadProps) {
    const total =
      getGrandTotal({ inventory: order.inventory, products: values.products })
        .stripeTotal / 100;
    const liftgate = order.palletCount > 12 ? false : values.liftgate;

    const userInfo = shipDetails
      ? {
          postalCode: values.zip,
          liftgate,
          appointment: values.appointment,
          residential: values.residential,
          insideDelivery: values.insideDelivery,
          shippingAddress: `${values.address1},
        ${values.city}, ${values.state} ${values.zip}`,
          addressLine1: values.address1,
          addressLine2: values.address2,
          city: values.city,
          state: values.state,
        }
      : undefined;

    const params = {
      leadId: order?.leadId,
      cartTotal: total,
      cartSummary: orderSummary(),
      shippingTotal,
      conversion,
      email: values.email.trim(),
      name: values.name.trim(),
      phone: values.phone.trim(),
      fulfillmentType: order?.fulfillmentType,
      audienceType: values.audienceType,
      ...userInfo,
    };

    const leadId = await updateAirtableLead(params);

    if (!order.leadId) {
      setOrder((s) => ({ ...s, leadId }));
    }
  }

  /**
   * Set the order's fufillment type
   */
  async function setFulfillmentType(type: 'Delivery' | 'Pickup') {
    setOrder((s) => ({ ...s, fulfillmentType: type }));
  }

  /**
   * Set preferred Distributor for pickup
   */
  function setPickupLocation(city: string) {
    const location = order.pickupLocations.find(
      ({ cityName }) => cityName === city
    );

    if (location) {
      setOrder((s) => ({ ...s, selectedPickupLocation: location }));
    }
  }

  /**
   * Generate order summary
   */
  function getOrderSummary(): CartItem[] {
    const items = values.products.map(({ sku, pallets }) => ({
      name: order.inventory[sku].name,
      pallets,
      price: pallets * order.inventory[sku].palletPrice,
    }));

    return items;
  }

  const orderSummary = () => {
    let str = '';
    const items = getOrderSummary();

    items.forEach(({ name, pallets, price }) => {
      str = str.concat(
        `${str.length ? '\n \n' : ''}${name} - $${formatNumber(
          price
        )}\n${pallets} Pallets`
      );
    });
    return str;
  };

  /**
   * Create / format order for Database
   */
  async function createOrder({
    stripeTotal,
    stripeId,
    shipping_total,
    loadRequestId,
    orderNumber,
    orderType,
    carrier,
    bolNumber,
    bolUrl,
    pickupTaxRate,
    shippingWarehouse,
    shippingProvider,
    shipmentType,
  }: OrderProps) {
    const isAdminOrder = orderType === 'Partner Order';
    const isShipping = order.fulfillmentType === 'Delivery';
    const liftgate = order.palletCount > 12 ? false : values.liftgate;

    const { total, total_products, tax, fee, shippingTotal, discount } =
      getGrandTotal({
        inventory: order.inventory,
        products: values.products,
        shipping: shipping_total,
        taxRate: pickupTaxRate || order.taxRates[values.state],
        processingFee: orderType === 'Credit Card',
        discount: order.partner?.discount,
      });

    const orderParams: Partial<OrderRequestParams> = {
      name: values.name.trim(),
      email: values.email.trim(),
      phone: values.phone.trim(),
      audienceType: values.audienceType,
      orderNumber,
      total: isAdminOrder ? total : stripeTotal,
      orderSummary: orderSummary(),
      orderJson: JSON.stringify(
        values.products.map(({ sku, pallets }) => ({ sku, quantity: pallets }))
      ),
      fulfillmentType: order.fulfillmentType,
      shippingProvider,
      bolNumber,
      bolUrl,
      shippingTotal: isShipping ? shippingTotal : undefined,
      shipDate: isShipping ? values.shipDate : undefined,
      shipmentType,
      shippingWarehouse: isShipping
        ? (shippingWarehouse as OrderRequestParams['shippingWarehouse'])
        : undefined,
      pickupDate: !isShipping ? values.pickupDate : undefined,
      pickupWarehouse: !isShipping
        ? (order.selectedPickupLocation
            .cityName as OrderRequestParams['pickupWarehouse'])
        : undefined,
      pickupLocation: order.selectedPickupLocation,
      cartItems: getOrderSummary(),
      loadRequestId,
      carrier,
      addressLine1: values.address1.trim(),
      addressLine2: values.address2?.trim(),
      city: values.city.trim(),
      stateCode: values.state,
      postalCode: values.zip.trim(),
      countryCode: values.country,
      shippingPhone: values.phone,
      liftgate: isShipping ? liftgate : undefined,
      residential: isShipping ? values.residential : undefined,
      appointment: isShipping ? values.appointment : undefined,
      insideDelivery: isShipping ? values.insideDelivery : undefined,
      paymentId: !isAdminOrder ? stripeId : undefined,
      paymentStatus: isAdminOrder ? 'No Payment' : 'Paid',
      paymentMethod: orderType,
      partner: order?.partner?.name,
      discount: order?.partner?.discount ?? undefined,
      discount_total: order?.partner?.discount ? discount : undefined,
      salesTax: tax,
      stripeFee: !isAdminOrder ? fee : 0,
    };

    const orderId = await createAirtableOrder(orderParams);
    setOrder((s) => ({
      ...s,
      orderId: orderId || undefined,
      orderComplete: true,
      orderNumber,
      total_products,
    }));
  }

  /**
   * Set the current payment method
   */
  function setPaymentType(type: 'Credit Card' | 'ACH') {
    setOrder((s) => ({ ...s, paymentType: type }));
  }

  /**
   * Set the pallet count
   */
  function setPalletCount(pallets: number) {
    setOrder((s) => ({ ...s, palletCount: pallets }));
  }

  /**
   * Set payment information
   */
  function setStripeDetails(id: string, amount: number) {
    setOrder((s) => ({ ...s, stripeAmount: amount, stripeId: id }));
  }

  /**
   * Set the order number
   */
  function setOrderNumber(id: number) {
    setOrder((s) => ({ ...s, orderNumber: id }));
  }

  /**
   * Set the current product context from the landing screen
   */
  function setProductType(type: 'hempwool' | 'hempcrete') {
    setOrder((s) => ({ ...s, productType: type }));
  }

  /**
   * Set the Hempcrete type
   */
  function setHempcreteType(type: 'block' | 'cast') {
    setOrder((s) => ({ ...s, hempcreteType: type }));
  }

  /**
   * Set the partner context
   */
  function setPartner(partner: Partner | undefined) {
    if (!partner) {
      setOrder((s) => ({ ...s, partner: undefined, paymentType: undefined }));
      return;
    }
    setOrder((s) => ({ ...s, partner, paymentType: 'Partner Order' }));
  }

  function setIsProcessing(isProcessing: boolean) {
    setOrder((s) => ({ ...s, isProcessing }));
  }

  return {
    order,
    getOrderSummary,
    setFulfillmentType,
    setPickupLocation,
    setPalletCount,
    updateLead,
    createOrder,
    paymentType: order.paymentType,
    isProcessing: order.isProcessing,
    setIsProcessing,
    setPaymentType,
    setStripeDetails,
    setOrderNumber,
    setProductType,
    setHempcreteType,
    setPartner,
  };
}

export { OrderProvider, useOrder };
