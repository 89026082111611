import { SVGProps } from 'react';

export const BackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 13" {...props}>
    <path d="M13.625 5.625H3.248L6.424 1.81A.876.876 0 005.076.69L.701 5.94a1.04 1.04 0 00-.078.131c0 .044 0 .07-.062.114-.04.1-.06.207-.061.315 0 .108.022.215.061.315 0 .044 0 .07.061.114.023.045.05.09.08.131l4.374 5.25a.874.874 0 00.674.315.875.875 0 00.674-1.435L3.247 7.375h10.378a.875.875 0 100-1.75z" />
  </svg>
);

export const AdminIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 505 505" {...props}>
    <path d="M477.5 270.181v-90.362l-54.624-13.005a179.4 179.4 0 00-8.766-21.131l29.436-47.834-63.896-63.896-47.834 29.437a179.316 179.316 0 00-21.13-8.766L297.681 0H207.32l-13.006 54.624a179.316 179.316 0 00-21.13 8.766L125.35 33.953 61.453 97.849l29.436 47.834a179.4 179.4 0 00-8.766 21.131L27.5 179.818v90.362l54.624 13.006a179.4 179.4 0 008.766 21.131l-29.575 48.059 44.304 42.436a138.543 138.543 0 00-7.119 43.881V505h310v-66.307c0-14.506-2.274-28.8-6.658-42.435l41.487-44.461-29.218-47.479a179.4 179.4 0 008.766-21.131zM378.5 475h-250v-36.307c0-20.806 5.928-41.066 17.144-58.591C165.722 348.729 199.946 330 237.193 330h32.614c37.247 0 71.471 18.729 91.549 50.101a108.499 108.499 0 0117.144 58.592zm-125-176.25c-38.253 0-69.375-31.122-69.375-69.375S215.247 160 253.5 160s69.375 31.122 69.375 69.375-31.122 69.375-69.375 69.375zm152.296 49.297l-17.443 18.693c-.57-.94-1.135-1.883-1.729-2.81-17.205-26.883-42.557-46.474-71.744-56.455 23.122-18.211 37.994-46.451 37.994-78.1 0-54.795-44.58-99.375-99.375-99.375s-99.375 44.58-99.375 99.375c0 31.649 14.872 59.889 37.994 78.1-29.187 9.98-54.539 29.572-71.744 56.455-.462.722-.897 1.458-1.345 2.187l-19.47-18.649 25.939-42.151-19.62-47.312-48.378-11.52v-42.971l48.379-11.519 19.62-47.312-26.078-42.376 30.385-30.385L172.183 98l47.312-19.62L231.015 30h42.971l11.519 48.379L332.817 98l42.376-26.078 30.385 30.385-26.078 42.376 19.62 47.312 48.379 11.519v42.971l-48.379 11.519-19.62 47.312z" />
  </svg>
);

export const RefreshIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path d="M13.65 2.35A7.958 7.958 0 008 0C3.58 0 .01 3.58.01 8S3.58 16 8 16c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 018 14c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L9 7h7V0l-2.35 2.35z" />
  </svg>
);

export const TrashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 13" {...props}>
    <path d="M10.214 11.781c0 .323-.124.633-.345.862a1.16 1.16 0 01-.833.357H1.964a1.16 1.16 0 01-.833-.357 1.24 1.24 0 01-.345-.862V3.25h9.428v8.531zm-6.678-6.5a.413.413 0 00-.115-.287.386.386 0 00-.556 0 .413.413 0 00-.115.287v5.688c0 .107.041.21.115.287a.386.386 0 00.556 0 .413.413 0 00.115-.287V5.28zm2.357 0a.413.413 0 00-.115-.287.386.386 0 00-.556 0 .413.413 0 00-.115.287v5.688c0 .107.042.21.115.287a.386.386 0 00.556 0 .413.413 0 00.115-.287V5.28zm2.357 0a.413.413 0 00-.115-.287.386.386 0 00-.556 0 .413.413 0 00-.115.287v5.688c0 .107.042.21.115.287a.386.386 0 00.556 0 .413.413 0 00.115-.287V5.28zM.393.812h2.946L3.57.338A.602.602 0 013.788.09c.093-.06.2-.091.31-.091h2.806c.11 0 .217.03.31.09s.168.146.216.248l.23.474h2.947c.104 0 .204.043.278.12.074.076.115.179.115.287v.812a.413.413 0 01-.115.287.386.386 0 01-.278.12H.393a.386.386 0 01-.278-.12A.413.413 0 010 2.031V1.22C0 1.11.041 1.007.115.93A.386.386 0 01.393.812z" />
  </svg>
);

export const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" {...props}>
    <path
      d="M9 8H1m8 8V8v8zm0-8V0v8zm0 0h8-8z"
      stroke="#9CCD32"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const ErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.07 512.07"
    {...props}>
    <path d="M256.035 320.004c-8.837 0-16-7.164-16-16v-112c0-8.836 7.163-16 16-16s16 7.164 16 16v112c0 8.837-7.163 16-16 16z" />
    <path d="M505.296 400.334l-199.19-331.98c-22.6-37.681-77.496-37.754-100.14 0L6.775 400.334c-18.837 31.395 3.826 71.67 40.58 71.67h417.36c36.76 0 59.414-40.28 40.581-71.67zm-40.581 39.67H47.356c-11.922 0-19.232-13.07-13.14-23.21l199.19-331.98c10.219-17.033 35.028-17.053 45.26 0l199.19 331.98c6.085 10.129-1.208 23.21-13.141 23.21z" />
    <circle cx="256.035" cy="360.004" r="24" />
  </svg>
);

export const DownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 292.362 292.362"
    {...props}>
    <path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z" />
  </svg>
);

export const TruckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54" {...props}>
    <path d="M14.625 42.75h-4.5a1.125 1.125 0 010-2.25h4.5a1.125 1.125 0 010 2.25zM51.188 42.75h-2.813a1.125 1.125 0 010-2.25h1.879l1.516-8.082c-.02-4.136-3.553-7.668-7.895-7.668h-7.387l-3.58 15.75h6.467a1.125 1.125 0 010 2.25H31.5a1.125 1.125 0 01-1.098-1.373l4.09-18a1.124 1.124 0 011.099-.877h8.284C49.457 22.5 54 27.043 54 32.625l-1.708 9.207a1.122 1.122 0 01-1.105.918z" />
    <path d="M43.875 47.25a5.631 5.631 0 01-5.625-5.625A5.631 5.631 0 0143.875 36c3.1 0 5.625 2.522 5.625 5.625a5.631 5.631 0 01-5.625 5.625zm0-9a3.38 3.38 0 00-3.375 3.375A3.38 3.38 0 0043.875 45a3.38 3.38 0 003.375-3.375 3.38 3.38 0 00-3.375-3.375zM19.125 47.25a5.631 5.631 0 01-5.625-5.625A5.631 5.631 0 0119.125 36c3.1 0 5.625 2.522 5.625 5.625a5.631 5.631 0 01-5.625 5.625zm0-9a3.38 3.38 0 00-3.375 3.375A3.38 3.38 0 0019.125 45a3.38 3.38 0 003.375-3.375 3.38 3.38 0 00-3.375-3.375zM14.625 22.5h-9a1.125 1.125 0 010-2.25h9a1.125 1.125 0 010 2.25zM14.625 29.25H3.375a1.125 1.125 0 010-2.25h11.25a1.125 1.125 0 010 2.25zM14.625 36h-13.5a1.125 1.125 0 010-2.25h13.5a1.125 1.125 0 010 2.25z" />
    <path d="M31.5 42.75h-7.875a1.125 1.125 0 010-2.25h6.977L35.715 18h-25.59a1.125 1.125 0 010-2.25h27a1.126 1.126 0 011.098 1.372l-5.625 24.75a1.126 1.126 0 01-1.098.878z" />
  </svg>
);

export const PlaidLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 126 48" {...props}>
    <defs>
      <path id="a" d="M0 47.473h126V0H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M66.248 16.268c-1.057-.889-2.861-1.333-5.413-1.333h-5.756v17.788h4.304v-5.575h1.928c2.34 0 4.056-.515 5.148-1.546 1.23-1.155 1.849-2.693 1.849-4.613 0-1.991-.687-3.565-2.06-4.721m-5.044 6.855h-1.821V18.96h1.636c1.99 0 2.985.698 2.985 2.094 0 1.378-.934 2.068-2.8 2.068m14.469-8.188h-4.488v17.788h9.69v-4.026h-5.202zm13.995 0l-7.05 17.788h4.832l.924-2.586H94.5l.845 2.586h4.886l-7-17.788h-3.563zm-.053 11.601l1.849-6.08 1.82 6.08h-3.67z"
        fill="#111"
      />
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        fill="#111"
        mask="url(#b)"
        d="M102.473 32.722h4.489V14.934h-4.489zm21.917-14.454a7.376 7.376 0 00-2.14-2.053c-1.355-.854-3.204-1.28-5.545-1.28h-5.914v17.787h6.918c2.5 0 4.506-.817 6.02-2.453 1.514-1.635 2.27-3.805 2.27-6.508 0-2.15-.537-3.981-1.61-5.493m-7.182 10.427h-1.927v-9.734h1.954c1.373 0 2.428.43 3.168 1.287.74.857 1.11 2.073 1.11 3.647 0 3.2-1.435 4.8-4.305 4.8M18.637 0L4.09 3.81.081 18.439l5.014 5.148L0 28.65l3.773 14.693 14.484 4.047 5.096-5.064 5.014 5.147 14.547-3.81 4.008-14.63-5.013-5.146 5.095-5.063L43.231 4.13 28.745.083l-5.094 5.063L18.637 0zM9.71 6.624l7.663-2.008 3.351 3.44-4.887 4.856L9.71 6.624zm16.822 1.478l3.405-3.383 7.63 2.132-6.227 6.187-4.808-4.936zm-21.86 9.136l2.111-7.705 6.125 6.288-4.886 4.856-3.35-3.44zm29.547-1.243l6.227-6.189 1.986 7.74-3.404 3.384-4.809-4.935zm-15.502-.127l4.887-4.856 4.807 4.936-4.886 4.856-4.808-4.936zm-7.814 7.765l4.886-4.856 4.81 4.936-4.888 4.856-4.808-4.936zm15.503.127l4.886-4.856L36.1 23.84l-4.887 4.856-4.807-4.936zM4.57 29.927l3.406-3.385 4.807 4.937-6.225 6.186-1.988-7.738zm14.021 1.598l4.887-4.856 4.808 4.936-4.886 4.856-4.809-4.936zm15.502.128l4.887-4.856 3.351 3.439-2.11 7.705-6.128-6.288zm-24.656 8.97l6.226-6.189 4.81 4.936-3.406 3.385-7.63-2.133zm16.843-1.206l4.886-4.856 6.126 6.289-7.662 2.007-3.35-3.44z"
      />
    </g>
  </svg>
);

export const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path d="M6.4 7L5 8.4l5 5 10-10L18.6 2 10 10.6z" />
    <path d="M9 17c-3.9 0-7-3.1-7-7s3.1-7 7-7c1.2 0 2.4.3 3.5.9l1-1.7C12.1 1.4 10.6 1 9 1c-5 0-9 4-9 9s4 9 9 9 9-4 9-9h-2c0 3.9-3.1 7-7 7z" />
  </svg>
);

export const PickupIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path d="M341.476 338.285c54.483-85.493 47.634-74.827 49.204-77.056C410.516 233.251 421 200.322 421 166 421 74.98 347.139 0 256 0 165.158 0 91 74.832 91 166c0 34.3 10.704 68.091 31.19 96.446l48.332 75.84C118.847 346.227 31 369.892 31 422c0 18.995 12.398 46.065 71.462 67.159C143.704 503.888 198.231 512 256 512c108.025 0 225-30.472 225-90 0-52.117-87.744-75.757-139.524-83.715zm-194.227-92.34a15.57 15.57 0 00-.517-.758C129.685 221.735 121 193.941 121 166c0-75.018 60.406-136 135-136 74.439 0 135 61.009 135 136 0 27.986-8.521 54.837-24.646 77.671-1.445 1.906 6.094-9.806-110.354 172.918L147.249 245.945zM256 482c-117.994 0-195-34.683-195-60 0-17.016 39.568-44.995 127.248-55.901l55.102 86.463a14.998 14.998 0 0025.298 0l55.101-86.463C411.431 377.005 451 404.984 451 422c0 25.102-76.313 60-195 60z" />
    <path d="M256 91c-41.355 0-75 33.645-75 75s33.645 75 75 75 75-33.645 75-75-33.645-75-75-75zm0 120c-24.813 0-45-20.187-45-45s20.187-45 45-45 45 20.187 45 45-20.187 45-45 45z" />
  </svg>
);

export const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0Zm1 15H9V9h2v6Zm0-8H9V5h2v2Z" />
  </svg>
);

export const BlockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 312 346" {...props}>
    <path d="M305.428 67.863 160.623 1.03a11.14 11.14 0 0 0-9.357 0L6.461 67.863A11.139 11.139 0 0 0 0 78V267.36a11.137 11.137 0 0 0 6.46 10.136l144.806 66.834a11.148 11.148 0 0 0 9.357 0l144.805-66.834a11.14 11.14 0 0 0 6.461-10.136V78a11.142 11.142 0 0 0-6.461-10.137ZM155.944 23.42 274.128 78 155.944 132.58 37.761 78l118.183-54.58ZM22.278 95.376l122.528 56.586v164.856L22.278 260.232V95.376Zm144.805 221.442V151.962l122.528-56.585v164.855l-122.528 56.586Z" />
  </svg>
);

export const MixIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 337 334" {...props}>
    <path
      fillRule="evenodd"
      d="M38.556 159.669H0v-28.547h337v28.547h-38.556v139.917c0 18.438-15.11 33.547-33.555 33.547H72.111c-18.445 0-33.555-15.109-33.555-33.547V159.669Zm231.333 144.917V159.669H67.111v144.917H269.89Z"
      clipRule="evenodd"
    />
    <path d="M260.06 101.848h-32.785l29.353-50.924L285.98 0l24.167 14.213-50.087 87.635Z" />
  </svg>
);

export const ArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.6 61.6" {...props}>
    <circle
      cx="30.8"
      cy="30.8"
      r="30"
      fill="none"
      stroke="var(--color-primary)"
      strokeWidth="1.629"
    />
    <path
      d="m40.5 31.5-6.9 6.7c-.2.2-.4.3-.7.3s-.5-.1-.7-.3-.3-.4-.3-.7.1-.5.3-.7l5.2-5.1H20.1c-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7 0-.3.1-.5.3-.7s.4-.3.7-.3h17.3l-5.2-5.1c-.1-.1-.2-.2-.2-.3-.1-.1-.1-.2-.1-.4 0-.1 0-.3.1-.4 0-.1.1-.2.2-.3s.2-.2.3-.2.2-.1.4-.1c.1 0 .3 0 .4.1s.2.1.3.2l6.9 6.8c.2.2.3.4.3.7 0 .3-.1.6-.3.8z"
      fill="var(--color-primary)"
    />
  </svg>
);

export const GearIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    {...props}
    fill="none">
    <path d="m13.3 6.453 1.927.315a.326.326 0 0 1 .273.322v1.793a.326.326 0 0 1-.27.321l-1.93.339c-.111.387-.265.76-.459 1.111l1.141 1.584a.326.326 0 0 1-.034.422l-1.268 1.268a.326.326 0 0 1-.418.037l-1.6-1.123c-.354.197-.73.354-1.118.468l-.34 1.921a.326.326 0 0 1-.322.269H7.09a.325.325 0 0 1-.321-.272l-.319-1.911a5.499 5.499 0 0 1-1.123-.465l-1.588 1.113a.326.326 0 0 1-.418-.037L2.052 12.66a.327.327 0 0 1-.035-.42l1.123-1.57a5.498 5.498 0 0 1-.47-1.129L.769 9.204A.326.326 0 0 1 .5 8.883V7.09c0-.16.115-.296.273-.322l1.901-.317c.115-.393.272-.77.47-1.128l-1.11-1.586a.326.326 0 0 1 .037-.417L3.34 2.053a.326.326 0 0 1 .42-.035l1.575 1.125a5.46 5.46 0 0 1 1.121-.46l.312-1.91A.326.326 0 0 1 7.09.5h1.793c.159 0 .294.114.322.27l.336 1.92c.389.112.764.268 1.12.465l1.578-1.135a.326.326 0 0 1 .422.033l1.268 1.268a.326.326 0 0 1 .036.418L12.84 5.342c.193.352.348.724.46 1.11v.001ZM5.716 8a2.283 2.283 0 1 0 4.566 0 2.283 2.283 0 0 0-4.566 0Z" />
  </svg>
);
