import dayjs from 'dayjs';

export const findNextWeekday = (date) => {
  if (date.day() === 6) {
    return date.add(2, 'day'); // Saturday -> Monday
  } else if (date.day() === 0) {
    return date.add(1, 'day'); // Sunday -> Monday
  }
  return date;
};

export const getStartDate = (dayBuffer = 7) => {
  const today = dayjs();
  let minDate = today.add(dayBuffer, 'day');
  minDate = findNextWeekday(minDate);
  return minDate;
};

export const isNotWeekend = (date) => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};
