type MaybeString = string | undefined | null;

/**
 * Returns a formatted selector string for `id` or `className` attributes that
 * merges user generated classNames.
 *
 * @param selectors - an array or any arbitrary number of params as selectors
 * Can be dynamically generated
 * Can be null or undefined
 *
 */
export function cn(
  ...selectors: (MaybeString | MaybeString[])[]
): string | undefined {
  const flattenedSelectors = selectors.flat();

  const s = flattenedSelectors
    ? flattenedSelectors
        .join(' ')
        .replace(/ +(?= )/g, '')
        .trim()
    : undefined;

  return s !== '' ? s : undefined;
}

/**
 * Formats a number into USD currency
 *
 * @param amount - the number to format
 */
export const formatUSD = (amount: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
};
