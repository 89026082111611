import type {
  FormValues,
  TokenResponse,
  QuoteResponse,
  OrderParams,
  QuoteParams,
} from '@hempitecture/types';
import { type OrderState, useError } from '@/context';
import type { PrimusRateResponse, PrimusCommodity } from '@hempitecture/primus';

const API_URL = process.env.NEXT_PUBLIC_HEMPITECTURE_API_URL as string;
const API_KEY = process.env.NEXT_PUBLIC_HEMPITECTURE_API_KEY as string;

export function usePrimus() {
  const { setError } = useError();

  /**
   * Get auth token for interacting with Primus API
   */
  async function getPrimusToken() {
    try {
      const { token }: TokenResponse = await fetch(
        '/api/primus/get-token'
      ).then((res) => {
        if (res.status === 500) {
          setError('COYOTE');
        }
        return res.json();
      });
      return token;
    } catch (err) {
      setError('COYOTE');
    }
  }

  /**
   * Get shipping rates from Primus
   */
  async function getPrimusRates(
    token: string,
    { order, values, shipmentType, distributor }: QuoteParams
  ) {
    try {
      const commodities = formatPrimusItems(values, order);
      let accessorialsList = getAccessorials(values, order);

      const { quotes, multiQuote, empty }: QuoteResponse = await fetch(
        `${API_URL}/primus/get-rates`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Origin:
              process.env.NODE_ENV === 'production'
                ? 'https://buy.hempitecture.com'
                : 'http://localhost:3000',
            Authorization: API_KEY,
          },
          body: JSON.stringify({
            token,
            shipmentType,
            params: {
              originCity: distributor.address.cityName,
              originState: distributor.address.stateCode,
              originZipcode: distributor.address.postalCode,
              originCountry: distributor.address.countryCode,
              destinationCity: values.city.trim(),
              destinationState: values.state,
              destinationZipcode: values.zip.trim(),
              destinationCountry: values.country,
              uom: 'us',
              accessorialsList,
              freightInfo:
                shipmentType === 'Multi-LTL' ? commodities : commodities[0],
              pickupDate: values.shipDate,
            },
          }),
        }
      ).then((res) => {
        if (!res.ok) {
          setError('QUOTES');
        }
        return res.json();
      });
      return { quotes, multiQuote, empty };
    } catch (err) {
      return { empty: true };
    }
  }

  /**
   * Save the selected shipping rate to Primus
   */
  async function savePrimusRate(token: string, rateId: string[]) {
    try {
      const { quoteNumber } = await fetch('/api/primus/save-rate', {
        method: 'POST',
        body: JSON.stringify({ token, rateId }),
      }).then((res) => {
        if (!res.ok) {
          setError('SAVE_RATE');
        }
        return res.json();
      });
      return quoteNumber as number[];
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Book a shipment with Primus
   */
  async function bookPrimusLoad(
    token: string,
    { order, values, shipping }: OrderParams
  ) {
    try {
      const commodities = formatPrimusItems(values, order);
      let accessorialsList = getAccessorials(values, order);

      const data: PrimusRateResponse = await fetch(
        '/api/primus/book-shipment',
        {
          method: 'POST',
          body: JSON.stringify({
            token,
            shipmentType: shipping.shipmentType,
            params: {
              shipper: {
                name: shipping.closestDistributor.name,
                address1: shipping.closestDistributor.line1,
                address2: shipping.closestDistributor.line2,
                city: shipping.closestDistributor.cityName,
                state: shipping.closestDistributor.stateCode,
                zipCode: shipping.closestDistributor.postalCode,
                country: shipping.closestDistributor.countryCode,
              },
              consignee: {
                name: values.name.trim(),
                address1: values.address1.trim(),
                address2: values.address2?.trim(),
                city: values.city.trim(),
                state: values.state,
                zipCode: values.zip.trim(),
                country: values.country,
                contactPhone: values.phone.trim(),
              },
              quoteNumber:
                shipping.shipmentType === 'Multi-LTL'
                  ? shipping.primusQuoteNumber
                  : shipping.primusQuoteNumber[0],
              uom: 'us',
              accessorialsList,
              lineItems:
                shipping.shipmentType === 'Multi-LTL'
                  ? commodities
                  : commodities[0],
              pickupInformation: {
                date: new Date(values.shipDate).toISOString().slice(0, 10),
                type: 'PO',
                timeFrom: '09:00',
                timeTo: '17:00',
              },
              deliveryInformation: {
                type: 'DO',
                timeFrom: '09:00',
                timeTo: '17:00',
              },
            },
          }),
        }
      ).then((res) => {
        if (!res.ok) {
          // setError('QUOTES');
        }
        return res.json();
      });
      return data;
    } catch (err) {}
  }

  return {
    getPrimusToken,
    getPrimusRates,
    savePrimusRate,
    bookPrimusLoad,
  };
}

function getAccessorials(values: FormValues, order: Partial<OrderState>) {
  const liftgate = order.palletCount > 12 ? false : values.liftgate;
  let accessorialsList = [];

  if (values.residential) {
    accessorialsList.push('RSD');
  }
  if (liftgate) {
    accessorialsList.push('LFD');
  }
  if (values.appointment) {
    accessorialsList.push('APD');
  }
  return accessorialsList;
}

interface Batch {
  pallets: number;
  products?: FormValues['products'];
}

function formatPrimusItems(values: FormValues, order: Partial<OrderState>) {
  let products = [values.products];
  let results: PrimusCommodity[][] = [];

  const isTruckload = order.palletCount > 12;

  // Determine how to split shipment
  if (order.palletCount > 6 && order.palletCount < 13) {
    let batch1: Batch = { pallets: 0, products: [] };
    let batch2: Batch = { pallets: 0, products: [] };

    values.products.forEach(({ sku, pallets }) => {
      if (batch1.pallets < 6) {
        const capacity = 6 - batch1.pallets;

        if (pallets <= capacity) {
          batch1.products.push({ pallets, sku });
          batch1.pallets += pallets;
        } else {
          batch1.products.push({ pallets: capacity, sku });
          batch1.pallets += capacity;
          batch2.products.push({ pallets: pallets - capacity, sku });
          batch2.pallets += pallets - capacity;
        }
      } else {
        batch2.products.push({ pallets, sku });
        batch2.pallets += pallets;
      }
    });
    products = [batch1.products, batch2.products];
  }

  products.forEach((values) => {
    let formatted = values.map(({ sku, pallets }) => {
      let commodity: PrimusCommodity = {
        qty: pallets,
        dimType: isTruckload ? 'TRUCK LOAD' : 'PLT',
        weight: order.inventory[sku].palletWeight,
        weightType: 'each',
        class: order.inventory[sku].freightClass,
        length: order.inventory[sku].palletLength,
        height: order.inventory[sku].palletHeight,
        width: order.inventory[sku].palletWidth,
        hazmat: false,
        stack: false,
        commodity: `Hempitecture SKU-${sku}`,
      };
      return commodity;
    });
    results.push(formatted);
  });

  return results;
}
