import { Modal } from '@maker-ui/elements';
import { cn } from '@/utils/helper';
import styles from './ErrorPopup.module.scss';

import { ErrorIcon } from '../../shared/Icons';

interface ErrorPopupProps {
  show: boolean;
  message: string;
}

/**
 * The `ErrorPopup` modal is activated when ErrorContext shows a critical error
 * anywhere in the app. It offers users a chance to hard refresh the page.
 */
export const ErrorPopup = ({ show, message }: ErrorPopupProps) => {
  function refreshPage() {
    window.location.reload();
  }

  return (
    <Modal show={show} background="rgba(123, 169, 24, 0.74)" center>
      <div
        className={cn('flex align-center justify-center', styles.root)}
        data-cy="error-popup">
        <div>
          <div className={cn('flex align-center justify-center', styles.title)}>
            <ErrorIcon />
            <h3>Error</h3>
          </div>
          <div className={styles.message}>{message}</div>
          <div className={styles.instructions}>
            Click the refresh button to try again. If the problem persists,
            please{' '}
            <a
              href="https://www.hempitecture.com/contact"
              target="_blank"
              rel="noreferrer">
              contact us
            </a>{' '}
            and we will help you complete your order.
          </div>
          <div className={styles.btn_wrapper}>
            <button className={styles.btn_reload} onClick={() => refreshPage()}>
              Reload Page
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
