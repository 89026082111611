import { useState, createContext, useContext } from 'react';
import { useFormikContext } from 'formik';
import type {
  FormValues,
  Shipment,
  MultiShipment,
  DistributorLocation,
  Location,
  OriginAPI,
  ShipmentType,
  PaymentType,
} from '@hempitecture/types';

import { useError, useOrder, OrderState } from '@/context';
import { useCoyote, useAirtable, usePrimus, useUberFreight } from '@/hooks';

export interface ShippingState {
  tokens: { coyote: string; primus: string; uber: string };
  loadRequestId: string | string[];
  closestDistributor: Location;
  distributors: DistributorLocation[];
  shipment: Shipment;
  params?: {
    order: Partial<OrderState>;
    values: FormValues;
    distributor: DistributorLocation;
  };
  fetchState: {
    emptyQuotes: number;
    isLoading: boolean;
    requestSubmitted: boolean;
    showCarousel: boolean;
    count: number;
    coyote: boolean;
    primus: boolean;
    uber: boolean;
  };
  quotes: Shipment[];
  multiQuotes: MultiShipment[];
  emptyQuotes?: number;
  multiShipment: MultiShipment;
  multiShipmentSelected: boolean;
  shipmentType: ShipmentType;
  shippingProvider: OriginAPI;
  shippingRate: number;
  primusQuoteNumber?: number[];
}

interface OrderShipmentProps {
  stripeId: string;
  stripeTotal: number;
  orderType: PaymentType;
}

const ShippingContext = createContext<{
  shipping: Partial<ShippingState>;
  setShipping: React.Dispatch<React.SetStateAction<Partial<ShippingState>>>;
}>({ shipping: {}, setShipping: () => {} });

const ShippingProvider = ({ children }: { children: React.ReactNode }) => {
  const [shipping, setShipping] = useState<Partial<ShippingState>>({
    quotes: [],
    multiQuotes: [],
    multiShipmentSelected: false,
    fetchState: {
      emptyQuotes: 0,
      isLoading: false,
      requestSubmitted: false,
      showCarousel: true,
      count: 0,
      coyote: false,
      primus: false,
      uber: false,
    },
  });

  return (
    <ShippingContext.Provider value={{ shipping, setShipping }}>
      {children}
    </ShippingContext.Provider>
  );
};

function useShipment() {
  const { setError } = useError();
  const { values }: { values: FormValues } = useFormikContext();
  const { order, setOrderNumber, createOrder } = useOrder();
  const { getCoyoteToken, createCoyoteLoad } = useCoyote();
  const { getPrimusToken, savePrimusRate, bookPrimusLoad } = usePrimus();
  const { getUberToken, bookUberLoad } = useUberFreight();
  const { getNearestDistributor } = useAirtable();
  const { shipping, setShipping } = useContext(ShippingContext);

  /**
   * Get Authentication tokens from shipping APIs for future requests
   */
  async function getAPITokens() {
    const coyote = await getCoyoteToken();
    const primus = await getPrimusToken();
    const uber = await getUberToken();
    setShipping((s) => ({ ...s, tokens: { coyote, primus, uber } }));
  }

  /**
   * Save the selected shipment to ShippingContext
   */
  function setShipment(option: ShippingState['shipment']) {
    setShipping((s) => ({
      ...s,
      shipment: option,
      shippingRate: option.totalRate,
      shippingProvider: option.originApi,
    }));
  }

  /**
   * Confirm the multi shipment option
   */
  function setMultiShipment(option: ShippingState['multiShipment']) {
    setShipping((s) => ({
      ...s,
      multiShipmentSelected: true,
      multiShipment: option,
      shippingRate: option.totalRate,
      shippingProvider: option.originApi,
    }));
  }

  /**
   * Save Rate (for Primus shipments only)
   */
  async function saveRate(quoteId: string[]) {
    const quoteNumber: number[] = await savePrimusRate(
      shipping.tokens.primus,
      quoteId
    );
    setShipping((s) => ({ ...s, primusQuoteNumber: quoteNumber }));
  }

  /**
   * Fetch quotes from shipping providers
   */
  async function formatShippingParams() {
    /** Check if we already have a nearest shipping provider and the shipping address has not changed */
    const alreadyExists =
      !!shipping.closestDistributor &&
      values.city === shipping.params.values.city &&
      values.state === shipping.params.values.state;

    const type =
      order.palletCount >= 13
        ? 'TL'
        : order.palletCount <= 6
        ? 'LTL'
        : 'Multi-LTL';

    // 1. Get list of distributors ranked in order of proximity
    const distributors = alreadyExists
      ? shipping.distributors
      : ((await getNearestDistributor(
          `${values.city}, ${values.state}`,
          order.pickupLocations
        )) as unknown as DistributorLocation[]);

    // 2. Confirm that there is enough inventory at the closest location
    function validateClosest() {
      let index = 0;
      let match: DistributorLocation = distributors[index];

      function validator() {
        // If Full Truckload, make sure to ship from US and not Canada
        if (order.palletCount > 12) {
          match = distributors.find(
            ({ address }) => address.countryCode === 'US'
          );
        }

        values.products.forEach(({ sku, pallets }) => {
          // If there is sufficient inventory, exit loop
          if (order.inventory[sku].inventory[match.locationKey] >= pallets) {
            return;
          }
          // If there is not enough inventory, check & validate the next location
          if (index < length) {
            index = index += 1;
            match = distributors[index];
            validator();
          } else {
            match = undefined;
            setError('INVENTORY_LEVELS');
          }
        });
      }
      validator();
      return match;
    }

    const closest: DistributorLocation =
      order.productType === 'hempcrete'
        ? ({
            address: order?.pickupLocations?.find(
              ({ locationKey }) => locationKey === 'ID'
            ),
            distance: 0,
            locationKey: 'ID',
            emails: 'tommy@hempitecture.com',
            // timezone: 'America/Boise',
          } as DistributorLocation)
        : validateClosest();

    const c =
      type === 'TL' &&
      closest.address.countryCode === 'CA' &&
      order.fulfillmentType === 'Delivery'
        ? {
            address: {
              ...closest.address,
              line1: '1064 1st NH Turnpike',
              line2: 'Unit 2',
              cityName: 'Northwood',
              cityAlias: 'Northwood',
              stateCode: 'NH',
              stateProvinceCode: 'NH',
              countryCode: 'US',
              postalCode: '03261',
            },
            distance: closest.distance,
            locationKey: closest.locationKey,
            emails: closest.emails,
            timezone: closest.timezone,
            contactPhone: closest.contactPhone,
          }
        : closest;

    if (closest) {
      const params = {
        order,
        values,
        distributor: c,
      };

      setShipping((s) => ({
        ...s,
        params,
        shipmentType: type,
        closestDistributor: {
          name: c.address.name,
          locationKey: c.locationKey,
          locationId: c.address.locationId,
          line1: c.address.line1,
          line2: c.address.line2,
          cityName: c.address.cityName,
          cityAlias: c.address.cityAlias,
          stateCode: c.address.stateCode,
          countryCode: c.address.countryCode,
          postalCode: c.address.postalCode,
          contactPhone: c.contactPhone,
          emails: c.emails,
          timezone: c.timezone,
        },
        distributors,
        fetchState: {
          ...s.fetchState,
          requestSubmitted: true,
          coyote: true,
          primus: true,
          uber: true,
          isLoading: true,
          showCarousel: false,
        },
      }));
      return {
        params,
        shipmentType: type,
        tokens: {
          coyote: shipping.tokens.coyote,
          primus: shipping.tokens.primus,
          uber: shipping.tokens.uber,
        },
      };
    }
    return true;
  }

  /**
   * Create Loads
   */
  async function createLoad() {
    const isMulti = shipping.shipmentType === 'Multi-LTL';
    const provider = isMulti
      ? shipping.multiShipment.originApi
      : shipping.shipment.originApi;
    // Book with Coyote
    const req1 =
      provider === 'COYOTE'
        ? await createCoyoteLoad(shipping.tokens.coyote, {
            order,
            values,
            shipping,
          })
        : undefined;
    // Book with Primus
    const req2 =
      provider === 'PRIMUS'
        ? await bookPrimusLoad(shipping.tokens.primus, {
            order,
            values,
            shipping,
          })
        : undefined;
    // Book with Uber
    const req3 =
      provider === 'UBER'
        ? await bookUberLoad(shipping.tokens.uber, {
            order,
            values,
            shipping,
          })
        : undefined;

    const orderNumber =
      req1?.orderNumber ||
      req3?.orderNumber ||
      Math.floor(100000 + Math.random() * 900000);
    const loadRequestId = req1?.loadRequestId || req3?.loadId;
    setOrderNumber(orderNumber);
    setShipping((s) => ({ ...s, loadRequestId }));
    return {
      loadRequestId,
      orderNumber,
      bolUrl: req2?.bolUrl,
      bolNumber: req2?.bolNumber,
    };
  }

  /**
   * Finalize Shipping Order
   */
  async function orderShipment({
    stripeId,
    stripeTotal,
    orderType,
  }: OrderShipmentProps) {
    const { loadRequestId, orderNumber, bolNumber, bolUrl } =
      await createLoad();

    function multiCarrier() {
      return shipping.multiShipment.carrier1.name ===
        shipping.multiShipment.carrier2.name
        ? shipping.multiShipment.carrier1.name
        : 'Check BOL for Carrier';
    }

    createOrder({
      stripeTotal,
      stripeId,
      shipping_total:
        shipping.shipmentType === 'Multi-LTL'
          ? shipping.multiShipment.totalRate
          : shipping.shipment.totalRate,
      loadRequestId,
      bolNumber,
      bolUrl,
      orderNumber,
      orderType,
      shipmentType: shipping.shipmentType,
      shippingProvider: shipping.shippingProvider,
      carrier:
        shipping.shipmentType === 'LTL'
          ? shipping.shipment.carrier
          : shipping.shipmentType === 'TL'
          ? 'Check BOL for Carrier'
          : multiCarrier(),
      shippingWarehouse: shipping.closestDistributor.cityName,
    });
  }

  return {
    shipping,
    setShipping,
    setShipment,
    setMultiShipment,
    formatShippingParams,
    getAPITokens,
    createLoad,
    orderShipment,
    saveRate,
    // freeShipping: values?.state === 'ID',
    freeShipping: false,
  };
}

export { ShippingProvider, useShipment };
