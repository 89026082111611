import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import type {
  FormValues,
  QuoteResponse,
  QuoteParams,
  OrderParams,
  TokenResponse,
} from '@hempitecture/types';
import type {
  CoyoteCommodity,
  CoyoteLoadCommodity,
  CreateLoadResponse,
  CoyoteQuoteRequest,
} from '@hempitecture/coyote';
import { OrderState, useError } from '@/context';

dayjs.extend(timezone);
dayjs.extend(utc);

type CommodityType = 'load' | 'quote';
type CommodityRes<T> = T extends 'quote'
  ? CoyoteCommodity[]
  : T extends 'load'
  ? CoyoteLoadCommodity[]
  : never;

export const fetchTimeout = (
  url: string,
  ms: number,
  { signal, ...options }: RequestInit = {}
) => {
  const controller = new AbortController();
  const promise = fetch(url, { signal: controller.signal, ...options });
  if (signal) signal.addEventListener('abort', () => controller.abort());
  const timeout = setTimeout(() => controller.abort(), ms);
  return promise.finally(() => clearTimeout(timeout));
};

const API_URL = process.env.NEXT_PUBLIC_HEMPITECTURE_API_URL as string;
const API_KEY = process.env.NEXT_PUBLIC_HEMPITECTURE_API_KEY as string;

export function useCoyote() {
  const { setError } = useError();

  /**
   * Get auth token for interacting with Coyote API
   */
  async function getCoyoteToken() {
    try {
      const { token }: TokenResponse = await fetch(
        '/api/coyote/get-token'
      ).then((res) => {
        if (res.status === 500) {
          setError('COYOTE');
        }
        return res.json();
      });
      return token;
    } catch (err) {
      setError('COYOTE');
    }
  }

  /**
   * Get shipping rates from Coyote
   */
  async function getCoyoteQuotes(
    token: string,
    { shipmentType, order, values, distributor }: QuoteParams
  ) {
    const liftgate = order.palletCount > 12 ? false : values.liftgate;

    try {
      const commodities = formatCoyoteItems('quote', values, order);
      const { quotes, multiQuote, empty }: QuoteResponse = await fetch(
        `${API_URL}/coyote/get-rates`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Origin:
              process.env.NODE_ENV === 'production'
                ? 'https://buy.hempitecture.com'
                : 'http://localhost:3000',
            Authorization: API_KEY,
          },
          body: JSON.stringify({
            token,
            shipmentType,
            quoteRequest: {
              origin: {
                cityName: distributor.address.cityName,
                stateCode: distributor.address.stateCode,
                countryCode: distributor.address.countryCode,
                postalCode: distributor.address.postalCode,
              },
              destination: {
                cityName: values.city.trim(),
                stateCode: values.state,
                countryCode: values.country,
                postalCode: values.zip.trim(),
              },
              pickupDate: formatDateTimezone(
                values.shipDate,
                distributor.timezone
              ),
              destinationRequirements: {
                residentialStop: values.residential,
                liftGateStop: liftgate,
                appointmentRequired: values.appointment,
              },
            },
            commodities:
              shipmentType === 'Multi-LTL' ? commodities : commodities[0],
          } as CoyoteQuoteRequest),
        }
      ).then((res) => {
        if (!res.ok) {
          setError('QUOTES');
        }
        return res.json();
      });
      return { quotes, multiQuote, empty };
    } catch (err) {
      return { empty: true };
      // setError('COYOTE');
    }
  }

  /**
   * Create a load with Coyote
   */
  async function createCoyoteLoad(
    token: string,
    { order, values, shipping }: OrderParams
  ) {
    const isMulti = shipping.shipmentType === 'Multi-LTL';
    const contactPhone = `Customer Tel#: ${values.phone}`;
    const liftgate = order.palletCount > 12 ? false : values.liftgate;
    const commodities = formatCoyoteItems('load', values, order);

    let requirements = [];

    if (liftgate) {
      requirements.push({
        requirementType: 'Liftgate',
        requirementValue: true,
      });
    }

    if (values.residential) {
      requirements.push({
        requirementType: 'Residential',
        requirementValue: true,
      });
    }

    const { orderNumber, loadRequestId }: CreateLoadResponse = await fetch(
      '/api/coyote/create-load',
      {
        method: 'POST',
        body: JSON.stringify({
          token,
          mode: shipping.shipmentType,
          multiLoad: isMulti
            ? [
                {
                  quoteId: shipping.multiShipment.carrier1.quoteId,
                  length: getLength(commodities[0]),
                  commodities: commodities[0],
                },
                {
                  quoteId: shipping.multiShipment.carrier2.quoteId,
                  length: getLength(commodities[commodities.length - 1]),
                  commodities: commodities[commodities.length - 1],
                },
              ]
            : undefined,
          spotQuoteId: !isMulti ? shipping.shipment.quoteId : undefined,
          equipmentLength: !isMulti ? getLength(commodities[0]) : undefined,
          stops: [
            {
              sequence: 1,
              stopType: 'Pickup',
              facility: {
                name: shipping.closestDistributor.name,
                address: {
                  line1: shipping.closestDistributor.line1,
                  line2: shipping.closestDistributor.line2,
                  cityName: shipping.closestDistributor.cityName,
                  stateProvinceCode: shipping.closestDistributor.stateCode,
                  postalCode: shipping.closestDistributor.postalCode,
                  countryCode: shipping.closestDistributor.countryCode,
                },
              },
              openDateTimeUtc: formatDateTimezone(
                values.shipDate,
                shipping.closestDistributor.timezone
              ),
              closeDateTimeUtc: formatDateTimezone(
                values.shipDate,
                shipping.closestDistributor.timezone,
                false
              ),
            },
            {
              sequence: 2,
              stopType: 'Delivery',
              facility: {
                name: values.name.trim(),
                address: {
                  line1: values.address1.trim(),
                  line2:
                    values.address2.length > 0
                      ? values.address2.trim()
                      : values.phone.trim(),
                  cityName: values.city.trim(),
                  stateProvinceCode: values.state,
                  postalCode: values.zip.trim(),
                  countryCode: values.country,
                },
              },
              stopRequirements: requirements.length ? requirements : undefined,
              stopNotes: values.phone,
            },
          ],
          commodities: !isMulti ? commodities[0] : undefined,
        }),
      }
    ).then((res) => {
      if (res.status === 500) {
        setError('LOAD');
      }
      return res.json();
    });
    return { orderNumber, loadRequestId };
  }

  return {
    getCoyoteToken,
    getCoyoteQuotes,
    createCoyoteLoad,
  };
}

function getLength(items: CoyoteLoadCommodity[]) {
  let totalLength = 0;

  items.forEach(({ palletCount, length }) => {
    totalLength += palletCount * length;
  });

  return totalLength;
}

function formatDateTimezone(
  shipDate: string,
  timezoneOffset: number,
  start = true
) {
  const targetDate = dayjs(shipDate)
    .utcOffset(timezoneOffset) // Adjusts the timezone offset
    .set('hour', start ? 9 : 17)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

  return targetDate.toISOString();
}

interface Batch {
  pallets: number;
  products?: FormValues['products'];
}

function formatCoyoteItems<T extends CommodityType>(
  type: T,
  values: FormValues,
  order: Partial<OrderState>
): [CommodityRes<T>] {
  let products = [values.products];
  let results = [];

  // Determine how to split shipment
  if (order.palletCount > 6 && order.palletCount < 13) {
    let batch1: Batch = { pallets: 0, products: [] };
    let batch2: Batch = { pallets: 0, products: [] };

    values.products.forEach(({ sku, pallets }) => {
      if (batch1.pallets < 6) {
        const capacity = 6 - batch1.pallets;

        if (pallets <= capacity) {
          batch1.products.push({ pallets, sku });
          batch1.pallets += pallets;
        } else {
          batch1.products.push({ pallets: capacity, sku });
          batch1.pallets += capacity;
          batch2.products.push({ pallets: pallets - capacity, sku });
          batch2.pallets += pallets - capacity;
        }
      } else {
        batch2.products.push({ pallets, sku });
        batch2.pallets += pallets;
      }
    });
    products = [batch1.products, batch2.products];
  }

  products.forEach((values) => {
    let formatted = values.map(({ sku, pallets }) => {
      let commodity: CoyoteCommodity | CoyoteLoadCommodity = {
        description: `SKU #${sku}`,
        palletCount: pallets,
        freightClass: `N${order.inventory[sku].freightClass}`,
        weight: order.inventory[sku].palletWeight * pallets,
        length: order.inventory[sku].palletLength,
        height: order.inventory[sku].palletHeight,
        width: order.inventory[sku].palletWidth,
      };
      if (type === 'load') {
        commodity = {
          ...commodity,
          packagingType: 'Pallet',
          quantity: pallets,
          cargoValue: pallets * order.inventory[sku].palletPrice,
          stackable: false,
          pickUpStopNumber: 1,
          deliveryStopNumber: 2,
        };
      }
      return commodity;
    }) as CommodityRes<T>;
    results.push(formatted);
  });

  return results as [CommodityRes<T>];
}
