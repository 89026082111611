import * as Yup from 'yup';
import type { FormValues } from '@hempitecture/types';
import { EventType } from '@/hooks';
import { getStartDate } from '@/utils/dates';

export const OrderSchema = Yup.object().shape({
  name: Yup.string().min(2, 'This name is too short').required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  phone: Yup.string()
    .min(10, 'Must be at least 10 digits and include an area code')
    .max(25, 'Phone number is too long. Please try again.')
    .required('Required'),
  audienceType: Yup.string()
    .notOneOf(['placeholder', ''], 'Please select another option')
    .required('Required'),
  products: Yup.array().of(
    Yup.object().shape({
      sku: Yup.mixed().required('Required'),
      sqft: Yup.number()
        .max(1000000, 'For an order this large, please contact our sales team.')
        .required('Please add a square footage.'),
    })
  ),
  address1: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string()
    .max(2, 'Please select a valid state')
    .required('Required'),
  zip: Yup.string()
    .max(7, 'Please use 5 digits (US) or 7 digits with a space (CA)')
    .min(5, 'Please use 5 digits (US) or 7 digits with a space (CA)')
    .required('Required'),
  shipDate: Yup.date().min(new Date(), 'You must select a date in the future'),
  pickupDate: Yup.date().min(
    new Date(),
    'You must select a date in the future'
  ),
});

export interface FormStepProps {
  formField?: object;
  nextStep?: (event?: EventType) => void;
}

// Set default pickup to next Monday

export const initialValues: FormValues = {
  name: '',
  email: '',
  phone: '',
  audienceType: '',
  products: [
    {
      sku: 0,
      sqft: 0,
      pallets: 0,
    },
  ],
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: 'US',
  liftgate: true,
  residential: true,
  appointment: false,
  insideDelivery: false,
  shipDate: getStartDate().toDate().toUTCString(),
  pickupDate: getStartDate().toDate().toUTCString(),
};
