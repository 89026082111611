import { Formik } from 'formik';
import type { FormValues } from '@hempitecture/types';

import { OrderSchema, initialValues } from '@/components/form/schema';
import { OrderProvider, ErrorProvider, ShippingProvider } from '@/context';

interface LayoutProps {
  children: React.ReactNode;
}

export const Providers = ({ children }: LayoutProps) => {
  return (
    <ErrorProvider>
      <Formik
        initialValues={initialValues}
        validationSchema={OrderSchema}
        validateOnBlur
        validateOnChange={false}
        onSubmit={(
          values: FormValues
          // { setSubmitting }: FormikHelpers<FormValues>
        ) => {
          console.log('submitting', values);
        }}>
        <OrderProvider>
          <ShippingProvider>{children}</ShippingProvider>
        </OrderProvider>
      </Formik>
    </ErrorProvider>
  );
};
