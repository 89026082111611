import type {
  FormValues,
  QuoteResponse,
  QuoteParams,
  OrderParams,
  TokenResponse,
  OrderState,
  DistributorLocation,
} from '@hempitecture/types';
import {
  InternalUberQuoteRequest,
  InternalUberTenderRequest,
  UberCreateLoadResponse,
  UberItem,
  UberStop,
} from '@hempitecture/uber';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useError } from '@/context';

dayjs.extend(timezone);
dayjs.extend(utc);

const API_URL = process.env.NEXT_PUBLIC_HEMPITECTURE_API_URL as string;
const API_KEY = process.env.NEXT_PUBLIC_HEMPITECTURE_API_KEY as string;

export function useUberFreight() {
  const { setError } = useError();
  /**
   * Get auth token for interacting with Primus API
   */
  async function getUberToken(setError?: () => void) {
    try {
      const { token }: TokenResponse = await fetch('api/uber/get-token').then(
        (res) => {
          if (res.status === 500) {
            setError?.();
          }
          return res.json();
        }
      );
      return token;
    } catch (err) {
      setError?.();
    }
  }

  /**
   * Get shipping rates from Uber Freight
   */
  async function getUberRates(
    token: string,
    { order, values, shipmentType, distributor }: QuoteParams,
    setError?: () => void
  ) {
    const items = formatUberItems(values, order);
    // Don't fetch rates if there are 6 pallets
    if (order.palletCount === 6) {
      return { quotes: [], multiQuote: null, empty: true };
    }

    const { quotes, multiQuote, empty }: QuoteResponse = await fetch(
      `${API_URL}/uber/get-rates`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Origin:
            process.env.NODE_ENV === 'production'
              ? 'https://buy.hempitecture.com'
              : 'http://localhost:3000',
          Authorization: API_KEY,
        },
        body: JSON.stringify({
          token,
          shipmentType,
          shipDate: values.shipDate,
          params: {
            items: shipmentType === 'Multi-LTL' ? items : items[0],
            originStop: formatUberStop(values, distributor, 'origin'),
            destinationStop: formatUberStop(values, distributor, 'destination'),
          },
        } as InternalUberQuoteRequest),
      }
    ).then((res) => {
      if (!res.ok) {
        setError?.();
      }
      return res.json();
    });
    return { quotes, multiQuote, empty };
  }

  /**
   * Book a tender with Uber
   */
  async function bookUberLoad(
    token: string,
    { order, values, shipping }: OrderParams
  ) {
    try {
      const items = formatUberItems(values, order);
      const shipmentType = shipping.shipmentType;
      console.log('Booking with Uber', items, shipmentType);

      const { orderNumber, loadId }: UberCreateLoadResponse = await fetch(
        '/api/uber/create-tender',
        {
          method: 'POST',
          body: JSON.stringify({
            token,
            shipmentType,
            shipDate: values.shipDate,
            quote_id:
              shipmentType === 'Multi-LTL'
                ? [
                    shipping.multiShipment.carrier1.quoteId,
                    shipping.multiShipment.carrier2.quoteId,
                  ]
                : shipping.shipment.quoteId,
            params: {
              items: shipmentType === 'Multi-LTL' ? items : items[0],
              originStop: formatUberStop(
                values,
                shipping.params.distributor,
                'origin'
              ),
              destinationStop: formatUberStop(
                values,
                shipping.params.distributor,
                'destination'
              ),
            },
          } as InternalUberTenderRequest),
        }
      ).then((res) => {
        if (res.status === 500) {
          console.log('500 error');
          setError('LOAD');
        }
        return res.json();
      });
      return { orderNumber, loadId };
    } catch (err) {
      console.log('Deeper 500 error', err);
      setError('LOAD');
    }
  }

  return {
    getUberToken,
    getUberRates,
    bookUberLoad,
  };
}

interface Batch {
  pallets: number;
  products: FormValues['products'];
}

function formatUberStop(
  values: FormValues,
  distributor: DistributorLocation,
  type: 'origin' | 'destination',
  isOrder?: boolean
): Omit<UberStop, 'items'> {
  // Get all destination requirements & filter out empty strings
  const destinationReqs = [
    values.insideDelivery ? 'INSIDE_HANDLING' : '',
    values.liftgate ? 'LIFT_GATE_REQUIRED' : '',
    values.appointment ? 'APPOINTMENT_REQUIRED' : '',
    values.residential ? 'RESIDENTIAL_LOCATION' : '',
  ].filter((r) => r !== '');
  const filteredReqs = destinationReqs.length > 0 ? destinationReqs : undefined;

  const isPickup = type === 'origin';
  const first_name = values.name.split(' ')[0];
  const last_name = values.name.split(' ')[1];
  const tz = distributor?.timezone;

  const dropOffDate = dayjs(values.shipDate).add(7, 'day').toISOString();

  return {
    sequence_number: type === 'origin' ? 1 : 2,
    type: isPickup ? 'PICKUP' : 'DROPOFF',
    mode: isPickup ? 'LIVE' : 'DROP',
    // @ts-ignore
    stop_requirements: !isPickup ? filteredReqs : undefined,
    facility: {
      facility_id: isPickup
        ? `HEMP_${distributor.locationKey}`
        : `CUSTOMER_${values.name.replace(' ', '_')}`,
      name: isPickup
        ? `${
            distributor.address?.cityAlias || distributor?.address?.cityName
          } Distributor`
        : values.name,
      address: {
        line1: isPickup ? distributor.address.line1 : values.address1,
        line2: isPickup ? distributor.address.line2 : values.address2,
        city: isPickup ? distributor.address.cityName : values.city,
        principal_subdivision: isPickup
          ? distributor.address.stateCode!
          : values.state,
        postal_code: isPickup ? distributor.address.postalCode : values.zip,
        country: 'USA',
      },
      contact: {
        first_name: isPickup ? 'Tommy' : first_name,
        last_name: isPickup ? 'Gibbons' : last_name,
        phone_number: isPickup ? distributor.contactPhone : values.phone,
      },
    },
    appointment: {
      status: 'NEEDED',
      start_time_utc: getUtcEpochTimestampForHour(values.shipDate, 9, tz),
      end_time_utc: getUtcEpochTimestampForHour(
        isPickup ? values.shipDate : dropOffDate.toString(),
        16,
        tz
      ),
    },
  };
}

function formatUberItems(values: FormValues, order: Partial<OrderState>) {
  let products = [values.products];
  let results: UberItem[][] = [];

  // Determine how to split shipment
  if (order.palletCount && order.palletCount > 6 && order.palletCount < 13) {
    let batch1: Batch = { pallets: 0, products: [] };
    let batch2: Batch = { pallets: 0, products: [] };

    values.products.forEach(({ sku, pallets }) => {
      if (batch1.pallets < 6) {
        const capacity = 6 - batch1.pallets;

        if (pallets && pallets <= capacity) {
          batch1.products.push({ pallets, sku });
          batch1.pallets += pallets;
        } else if (pallets) {
          batch1.products.push({ pallets: capacity, sku });
          batch1.pallets += capacity;
          batch2.products.push({ pallets: pallets - capacity, sku });
          batch2.pallets += pallets - capacity;
        }
      } else {
        batch2.products.push({ pallets, sku });
        batch2.pallets += pallets!;
      }
    });
    products = [batch1.products, batch2.products];
  }

  products.forEach((values) => {
    let formatted = values.map(({ sku, pallets }) => {
      let commodity: UberItem = {
        name: `Hempitecture SKU-${sku}`,
        package_count: {
          type: 'PALLET',
          count: pallets!,
        },
        weight: {
          amount: order.inventory![sku].palletWeight!,
          unit: 'LB',
        },
        dimensions: {
          length: order.inventory![sku].palletLength!,
          height: order.inventory![sku].palletHeight!,
          width: order.inventory![sku].palletWidth!,
          unit: 'IN',
        },
        freight_class: `${order.inventory![sku].freightClass}`,
      };
      return commodity;
    });
    results.push(formatted);
  });

  return results;
}

function getUtcEpochTimestampForHour(
  date: string,
  hour: number,
  timezoneOffset: number
): number {
  try {
    const initialDate = dayjs(date).utcOffset(timezoneOffset);
    // Directly set the date and time in the specified timezone using utcOffset
    const targetDate = dayjs(initialDate)
      .set('hour', hour)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0);

    // Convert to UTC timestamp in seconds
    const utcTimestamp = targetDate.unix(); // Day.js provides the .unix() method to get the Unix timestamp in seconds

    return utcTimestamp;
  } catch (err) {
    console.error('Failed to compute UTC timestamp:', err);
    return null;
  }
}
